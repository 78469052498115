import { reactive, readonly } from 'vue';
import router from '@/router';
import axios from 'axios';
import * as jose from "jose";

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? "https://dmdlogisticsadmin.net" : "http://localhost:1002",
  withCredentials: false
});

const state = reactive({
  loading: false,
  error: null,
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');

  if (token) {
    let decoded = jose.decodeJwt(token);
    const currentTime = Math.floor(Date.now() / 1000);

    if (decoded.exp < currentTime) {
      localStorage.removeItem('token');
      await router.push('/login');
      return Promise.reject('Session Expired');
    }

    config.headers.Authorization = 'Bearer ' + token;
  }

  state.loading = true;
  return config;
}, (error) => {
  state.loading = false;
  state.error = error;
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  state.loading = false;
  return response;
}, async (error) => {
  state.loading = false;
  state.error = error;
  // Handle 404 error
  if (error.response && error.response.status === 404) {
    await router.push('/error/404')
  }
  // Handle 401 error
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('usr');
    await router.push('/login');
  }
  return Promise.reject(error);
});

export const axiosPlugin = {
  install: (app) => {
    app.config.globalProperties.$axios = axiosInstance;
    app.provide('axios', axiosInstance);
    app.config.globalProperties.$httpState = readonly(state);
  },
};
